import React, { useContext, useState } from 'react';
import { Box, TextField, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Select, MenuItem, Button, InputLabel, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { DarkModeContext } from '../DarkModeContext';
import emailjs from 'emailjs-com';
import useRazorpay from "react-razorpay";

const SubscriptionForm = ({ formType, handleClose, milkType }) => {
    const [Razorpay] = useRazorpay();
    const theme = useTheme();
    const { darkMode } = useContext(DarkModeContext);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [formVisible, setFormVisible] = useState(true);
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(false)

    const [formValues, setFormValues] = useState({
        name: '',
        contactNo: '',
        email: '',
        subscription: '',
        quantity: {
            '500ml': 0,
            '1Ltr': 0,
        },
        pincode: 562125,
        village: '',
        community: '',
        residenceType: '',
        block: '',
        flatNo: '',
        villaNo: '',
        mrp: 0,
        totalCost: 0,
    });

    const prices = {
        '500ml': 35,
        '1Ltr': 65,
    };

    const subscriptionPrices = {
        'Weekly': 7,
        '1 Month': 30,
        '2 Months': 60,
        '3 Months': 90,
    };

    const communities = {
        Billapura: [
            { name: "Confident Atria Apartment", blocks: ["A"] },
            { name: "Peninsula Pinnacle Apartment", blocks: ["A", "B", "C", "D", "E"] },
            { name: "Dx Max Apartment", blocks: ["A", "B", "C", "D", "E", "F", "G"] },
            { name: "Mayur Daffodils Apartment", blocks: ["A", "B", "C", "D", "E", "F", "G"] },
            { name: "Confident Bellatrix Villa", blocks: [] },
            { name: "Azven Realty Breathe Villa", blocks: [] },
            { name: "Aishwarya Samskruthi Villa", blocks: [] },
        ],
        Sompura: [
            { name: "Kristal - Sovereign Santhinivasa Apartment", blocks: ["A", "B", "C", "D"] }
        ],
        Handenahalli: [
            { name: "JRC Palladio", blocks: [] },
            { name: "Evo Homes", blocks: [] },
            { name: "JRC Sanzio", blocks: [] },
            { name: "Casagrand florella", blocks: [] },
            { name: "Casagrand florella Phase - 2", blocks: [] }
        ]

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => {
            const newValues = { ...prevValues, [name]: value };
            if (name === 'village' || name === 'community') {
                const selectedCommunity = communities[newValues.village]?.find(c => c.name === newValues.community);
                if (selectedCommunity) {
                    newValues.residenceType = selectedCommunity.name.toLowerCase().includes('apartment') ? 'Apartment' : 'Villa';
                    newValues.block = '';
                    newValues.flatNo = '';
                    newValues.villaNo = '';
                } else {
                    newValues.residenceType = '';
                    newValues.block = '';
                    newValues.flatNo = '';
                    newValues.villaNo = '';
                }
            }
            if (name === 'subscription') {
                const newTotalCost = Object.keys(newValues.quantity).reduce((total, key) => total + newValues.quantity[key] * prices[key], 0);
                newValues.totalCost = newTotalCost * subscriptionPrices[newValues.subscription];
            }
            return newValues;
        });
    };

    const handleQuantityChange = (type, operation) => {
        setFormValues(prev => {
            const newQuantity = {
                ...prev.quantity,
                [type]: operation === 'increment' ? prev.quantity[type] + 1 : Math.max(prev.quantity[type] - 1, 0),
            };
            let newTotalCost = Object.keys(newQuantity).reduce((total, key) => total + newQuantity[key] * prices[key], 0);
            if (formValues.subscription) {
                newTotalCost = newTotalCost * subscriptionPrices[formValues.subscription];
            }
            return {
                ...prev,
                quantity: newQuantity,
                totalCost: newTotalCost
            };
        });
    };

    const textFieldStyle = {
        input: { color: darkMode ? 'white' : 'black' },
        label: { color: darkMode ? 'white' : 'black' },
        '& .MuiInput-underline:before': {
            borderBottomColor: darkMode ? 'white' : 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: darkMode ? 'white' : 'black',
        },
    };

    const selectFieldStyle = {
        color: darkMode ? 'white' : 'black',
        '& .MuiSvgIcon-root': {
            color: darkMode ? 'white' : 'black',
        },
        '& .MuiInputLabel-root': {
            color: darkMode ? 'white' : 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: darkMode ? 'white' : 'black',
            },
            '&:hover fieldset': {
                borderColor: darkMode ? 'white' : 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: darkMode ? 'white' : 'black',
            },
        },
    };

    const buttonStyle = {
        width: !isDesktop ? '50%' : '40%',
        background: '#1A8F85',
        color: 'white',
        marginTop: '1%',
        borderRadius: 0,
        padding: isDesktop ? '2%' : '4% 2%',
        boxShadow: 'none'
    };

    if (!formVisible) return null;


    const options = {
        key: "rzp_live_9izJRt83U1lW4E",
        // key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: (formValues.totalCost - formValues.totalCost * 0.1) * 100,
        // amount: 1 * 100,
        currency: "INR",
        name: "MilkPromise",
        description: "Services with a Lifestyle",
        image: '/images/Milk Promise-pay.jpg',

        handler: async function (response) {

            setLoading(true);
            let payload = {
                orderId: response.razorpay_payment_id,
                to_name: 'MilkPromise',
                qty500: formValues?.quantity?.['500ml'],
                qty1000: formValues?.quantity?.['1Ltr'],
                milkType: milkType,
                ...formValues,  // This will include all keys and values from formValues
            };

            // console.log('payloadpayloadpayload',payload);
            emailjs.send(
                'service_9k8r7wk',
                'template_2a6gb19',
                payload,
                'fJrp5_Z6y8nXUV6Ze'
            ).then((result) => {
                alert(`
Order Confirmed!

Thank you for your purchase. Your payment has been successfully processed.

Order Details:

Product: Fresh Cow Milk
Quantity: ${formValues?.quantity?.['1Ltr'] + (formValues?.quantity?.['500ml'] / 2)} Litre
Amount: ₹${formValues.totalCost}
Discount: - ₹${formValues.totalCost * 0.1}
Total Amount: ₹${formValues.totalCost - formValues.totalCost * 0.1}
Your order is being processed and will be delivered soon. You will receive a notification once it's on the way.

For any inquiries, please contact us at +91 9844884464.
                    `);
                handleClose()

                setStatus(true);
                setLoading(false);

                let customerPayload = {
                    orderId: response.razorpay_payment_id,
                    to_name: formValues?.name,
                    qty500: formValues?.quantity?.['500ml'],
                    qty1000: formValues?.quantity?.['1Ltr'],
                    milkType: milkType,
                    deliveryTime: formValues?.deliveryTime,
                    ...formValues,
                };


                emailjs.send(
                    'service_9k8r7wk',
                    'template_mnq89cn',
                    customerPayload,
                    'fJrp5_Z6y8nXUV6Ze'
                ).then((result) => {
                    setLoading(false);
                }, (error) => {
                    // console.log('Error in sending second email', error);
                    setLoading(false);
                });

            }, (error) => {
                setStatus(false);
                setLoading(false);
            });

        },

        prefill: {
            name: formValues?.name,
            email: formValues?.email,
            contact: formValues?.contactNo,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#5DB761",
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };


    return (
        <Box style={{ padding: '2% 5%', position: 'relative', background: 'transparent' }}>

            <IconButton
                style={{ position: 'absolute', top: 10, right: 10, color: darkMode ? 'white' : 'black' }}
                onClick={handleClose}
            >
                <Close />
            </IconButton>

            <form onSubmit={handleSubmit} style={{ zIndex: 99 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                    <TextField
                        variant='standard'
                        label="Your Name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        fullWidth
                        sx={textFieldStyle}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            variant='standard'
                            label="Contact No."
                            name="contactNo"
                            value={formValues.contactNo}
                            onChange={handleInputChange}
                            fullWidth
                            sx={textFieldStyle}
                        />
                        <TextField
                            variant='standard'
                            label="Email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            fullWidth
                            sx={textFieldStyle}
                        />
                    </Box>

                    <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Quantity</Typography>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: isDesktop ? 'row' : 'column' }}>
                        {['500ml', '1Ltr'].map((type) => (
                            <Box key={type} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                    <Typography style={{ color: darkMode ? 'white' : 'black', width: isDesktop ? '' : 100 }}>{type}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Button variant="outlined" onClick={() => handleQuantityChange(type, 'decrement')} style={{ color: darkMode ? 'white' : 'black', borderColor: darkMode ? 'white' : 'black' }}>-</Button>
                                        <Typography style={{ color: darkMode ? 'white' : 'black' }}>{formValues.quantity[type]}</Typography>
                                        <Button variant="outlined" onClick={() => handleQuantityChange(type, 'increment')} style={{ color: darkMode ? 'white' : 'black', borderColor: darkMode ? 'white' : 'black' }}>+</Button>
                                    </Box>
                                </div>
                                <Typography style={{ fontSize: 12, color: darkMode ? 'white' : 'black' }}>
                                    <span>Price: </span><span style={{ textDecoration: 'line-through' }}>₹{type === '500ml' ? '37' : '69'}</span> ₹{type === '500ml' ? '35' : '65'}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    {formType !== 'subscribe' &&
                        (
                            <>
                                <Box sx={{ display: 'flex', gap: 2, flexDirection: isDesktop ? 'row' : 'column' }}>

                                    <FormControl component="fieldset" variant="standard">
                                        <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Packaging</Typography>
                                        <RadioGroup
                                            row
                                            name="packaging"
                                            value={formValues.packaging}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Bottle"
                                                control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />}
                                                label="Bottle"
                                                style={{ color: darkMode ? 'white' : 'black' }}
                                            />
                                            <FormControlLabel
                                                value="Packet"
                                                control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />}
                                                label="Packet"
                                                style={{ color: darkMode ? 'white' : 'black' }}
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                </Box>
                            </>)
                    }


                    <Box sx={{ display: 'flex', gap: 2, flexDirection: isDesktop ? 'row' : 'column' }}>
                        <FormControl component="fieldset" variant="standard">
                            <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Delivery time</Typography>
                            <RadioGroup
                                row
                                name="deliveryTime"
                                value={formValues.packaging}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="morning" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="Morning 6-7" style={{ color: darkMode ? 'white' : 'black' }} />
                                <FormControlLabel value="evening" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="Evening 5-6" style={{ color: darkMode ? 'white' : 'black' }} />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {formType === 'subscribe' && (
                        <>
                            <FormControl component="fieldset" variant="standard">
                                <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Subscription</Typography>
                                <RadioGroup
                                    row
                                    name="subscription"
                                    value={formValues.subscription}
                                    onChange={handleInputChange}
                                >
                                    <FormControlLabel value="Weekly" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="Weekly" style={{ color: darkMode ? 'white' : 'black' }} />
                                    <FormControlLabel value="1 Month" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="1 Month" style={{ color: darkMode ? 'white' : 'black' }} />
                                    <FormControlLabel value="2 Months" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="2 Months" style={{ color: darkMode ? 'white' : 'black' }} />
                                    <FormControlLabel value="3 Months" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="3 Months" style={{ color: darkMode ? 'white' : 'black' }} />
                                </RadioGroup>
                            </FormControl>
                        </>
                    )}

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel style={{ color: darkMode ? 'white' : 'black' }}>Select Pincode</InputLabel>
                            <Select
                                name="pincode"
                                value={formValues.pincode}
                                onChange={handleInputChange}
                                sx={selectFieldStyle}
                            >
                                <MenuItem value="562125">562125</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="standard">
                            <InputLabel style={{ color: darkMode ? 'white' : 'black' }}>Select Village</InputLabel>
                            <Select
                                name="village"
                                value={formValues.village}
                                onChange={handleInputChange}
                                sx={selectFieldStyle}
                            >
                                <MenuItem value="Billapura">Billapura</MenuItem>
                                <MenuItem value="Sompura">Sompura</MenuItem>
                                <MenuItem value="Handenahalli">Handenahalli</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="standard">
                            <InputLabel style={{ color: darkMode ? 'white' : 'black' }}>Select Community Name</InputLabel>
                            <Select
                                name="community"
                                value={formValues.community}
                                onChange={handleInputChange}
                                sx={selectFieldStyle}
                            >
                                {formValues.village && communities[formValues.village].map((community) => (
                                    <MenuItem key={community.name} value={community.name}>{community.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <FormControl component="fieldset" variant="standard">
                        <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Residence Type</Typography>
                        <RadioGroup
                            row
                            name="residenceType"
                            value={formValues.residenceType}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Apartment" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="Apartment" style={{ color: darkMode ? 'white' : 'black' }} />
                            <FormControlLabel value="Villa" control={<Radio sx={{ color: darkMode ? 'white' : 'black', '&.Mui-checked': { color: darkMode ? 'white' : 'primary' } }} />} label="Villa" style={{ color: darkMode ? 'white' : 'black' }} />
                        </RadioGroup>
                    </FormControl>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel style={{ color: darkMode ? 'white' : 'black' }}>Select Block</InputLabel>
                            <Select
                                name="block"
                                value={formValues.block}
                                onChange={handleInputChange}
                                sx={selectFieldStyle}
                                disabled={!(communities[formValues.village]?.find(c => c.name === formValues.community)?.blocks.length)}
                            >
                                {communities[formValues.village]?.find(c => c.name === formValues.community)?.blocks.length ? (
                                    communities[formValues.village]?.find(c => c.name === formValues.community)?.blocks.map((block) => (
                                        <MenuItem key={block} value={block}>{block}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="No options">No options</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {formValues.residenceType === 'Apartment' && (
                            <TextField
                                variant='standard'
                                label="Flat No"
                                name="flatNo"
                                value={formValues.flatNo}
                                onChange={handleInputChange}
                                fullWidth
                                sx={textFieldStyle}
                            />
                        )}
                        {formValues.residenceType === 'Villa' && (
                            <TextField
                                variant='standard'
                                label="Villa No"
                                name="villaNo"
                                value={formValues.villaNo}
                                onChange={handleInputChange}
                                fullWidth
                                sx={textFieldStyle}
                            />
                        )}
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" style={{ color: darkMode ? 'white' : 'black' }}>Cost: ₹{formValues.totalCost}</Typography>
                        <Typography style={{ color: darkMode ? 'white' : 'black', marginTop: '0px', paddingTop: '0px' }}>Discount: - ₹{formValues.totalCost * 0.1}</Typography>
                        <Typography style={{ color: darkMode ? 'white' : 'black' }}>Total: ₹{formValues.totalCost - formValues.totalCost * 0.1}</Typography>
                        <Button onClick={(e) => handleSubmit(e)} type="submit" variant="contained" color="primary" style={buttonStyle}>
                            Subscribe Now
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
    )
};

export default SubscriptionForm;